/*#region Fonts */
/* Raleway: Black */
@font-face {
  font-display: swap;
  font-family: 'RalewayBlack';
  font-weight: 900;
  src: local('RalewayBlack'),
    url(./assets/fonts/Raleway/Raleway-Black.ttf) format('truetype');
}

/* Raleway: BlackItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayBlackItalic';
  font-style: italic;
  font-weight: 900;
  src: local('RalewayBlackItalic'),
    url(./assets/fonts/Raleway/Raleway-BlackItalic.ttf) format('truetype');
}

/* Raleway: Bold */
@font-face {
  font-display: swap;
  font-family: 'RalewayBold';
  font-weight: 700;
  src: local('RalewayBold'),
    url(./assets/fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}

/* Raleway: BoldItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayBoldItalic';
  font-style: italic;
  font-weight: 700;
  src: local('RalewayBoldItalic'),
    url(./assets/fonts/Raleway/Raleway-BoldItalic.ttf) format('truetype');
}

/* Raleway: ExtraBold */
@font-face {
  font-display: swap;
  font-family: 'RalewayExtraBold';
  font-weight: 800;
  src: local('RalewayExtraBold'),
    url(./assets/fonts/Raleway/Raleway-ExtraBold.ttf) format('truetype');
}

/* Raleway: ExtraBoldItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayExtraBoldItalic';
  font-style: italic;
  font-weight: 800;
  src: local('RalewayExtraBoldItalic'),
    url(./assets/fonts/Raleway/Raleway-ExtraBoldItalic.ttf) format('truetype');
}

/* Raleway: ExtraLight */
@font-face {
  font-display: swap;
  font-family: 'RalewayExtraLight';
  font-weight: 200;
  src: local('RalewayExtraLight'),
    url(./assets/fonts/Raleway/Raleway-ExtraLight.ttf) format('truetype');
}

/* Raleway: ExtraLightItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayExtraLightItalic';
  font-style: italic;
  font-weight: 200;
  src: local('RalewayExtraLightItalic'),
    url(./assets/fonts/Raleway/Raleway-ExtraLightItalic.ttf) format('truetype');
}

/* Raleway: Light */
@font-face {
  font-display: swap;
  font-family: 'RalewayLight';
  font-weight: 300;
  src: local('RalewayLight'),
    url(./assets/fonts/Raleway/Raleway-Light.ttf) format('truetype');
}

/* Raleway: LightItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayLightItalic';
  font-style: italic;
  font-weight: 300;
  src: local('RalewayLightItalic'),
    url(./assets/fonts/Raleway/Raleway-LightItalic.ttf) format('truetype');
}

/* Raleway: Medium */
@font-face {
  font-display: swap;
  font-family: 'RalewayMedium';
  font-weight: 500;
  src: local('RalewayMedium'),
    url(./assets/fonts/Raleway/Raleway-Medium.ttf) format('truetype');
}

/* Raleway: MediumItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayMediumItalic';
  font-weight: 500;
  src: local('RalewayMediumItalic'),
    url(./assets/fonts/Raleway/Raleway-MediumItalic.ttf) format('truetype');
}

/* Raleway: Regular */
@font-face {
  font-display: swap;
  font-family: 'RalewayRegular';
  font-weight: 400;
  src: local('RalewayRegular'),
    url(./assets/fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

/* Raleway: RegularItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayRegularItalic';
  font-style: italic;
  font-weight: 400;
  src: local('RalewayRegularItalic'),
    url(./assets/fonts/Raleway/Raleway-Italic.ttf) format('truetype');
}

/* Raleway: SemiBold */
@font-face {
  font-display: swap;
  font-family: 'RalewaySemiBold';
  font-weight: 600;
  src: local('RalewaySemiBold'),
    url(./assets/fonts/Raleway/Raleway-SemiBold.ttf) format('truetype');
}

/* Raleway: SemiBoldItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewaySemiBoldItalic';
  font-style: italic;
  font-weight: 600;
  src: local('RalewaySemiBoldItalic'),
    url(./assets/fonts/Raleway/Raleway-SemiBoldItalic.ttf) format('truetype');
}

/* Raleway: Thin */
@font-face {
  font-display: swap;
  font-family: 'RalewayThin';
  font-weight: 100;
  src: local('RalewayThin'),
    url(./assets/fonts/Raleway/Raleway-Thin.ttf) format('truetype');
}

/* Raleway: ThinItalic */
@font-face {
  font-display: swap;
  font-family: 'RalewayThinItalic';
  font-style: italic;
  font-weight: 100;
  src: local('RalewayThinItalic'),
    url(./assets/fonts/Raleway/Raleway-ThinItalic.ttf) format('truetype');
}
/*#endregion /Fonts */

/*#region Default: Reset */
* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
  /* user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'RalewayRegular', sans-serif;
  font-weight: 400;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*#endregion /Default: Reset */

/*#region Native Styling */
a {
  text-decoration: none;
}
/*#endregion /Native Styling */
